import React from 'react'
import { graphql} from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ItemPreview from '../components/itemPreview/item-preview'

class PlacesPage extends React.Component {
  render() {
    const allPlaces = get(this, 'props.data.allContentfulPlace.nodes')
    // const neighborhoods = get(this, 'props.data.allContentfulNeighborhood.nodes')
    

    return (
      <Layout location={this.props.location}>
        <Seo title="Places" />
        <Hero title="Places" />

        <ItemPreview items={allPlaces} slugName="place" />

         
      </Layout>
    )
  }
}

export default PlacesPage

export const pageQuery = graphql`
  query PlacesPageQuery {
    allContentfulPlace {
      nodes {
        name
        slug
      }
    }
  }
`
