import React from 'react'
import { Link } from 'gatsby'
// import { GatsbyImage } from 'gatsby-plugin-image'

// import Container from './container'
import * as styles from './item-preview.module.scss'


const ItemPreview = ({ items, slugName}) => {
  if (!items) return null
  if (!Array.isArray(items)) return null

  return (
    // <Container>
      <div className={styles.itemList}>
        {items.map((item) => {
          return (
            <div className={styles.item} key={item.slug}>
              <Link to={`/${slugName}/${item.slug}`} className={styles.link}>
                <div className={styles.content}>
                  {item.name &&
                    <h2 className={styles.title}>{item.name}</h2>
                  }
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    // </Container>
  )
}

export default ItemPreview
